<template>
<div>
    <GeneralComponent></GeneralComponent>
</div>
</template>

<script>
// این ویو صفحه عمومی سرمایه گذاری می باشد که اطلاعات جامعی از سرمایه گذاری کاربر را برمیگرداند
import GeneralComponent from '@/components/Investment/General.vue'

export default {
    name: 'General',
    components: {
        GeneralComponent
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
}
</script>
